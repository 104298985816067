/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

/** this file is used by project to add new icons */
import dynamicHydrate from '@bluheadless/ui-logic/src/dynamic-hydration/dynamic-hydrate'
import EmptyIcon from '../icon/empty'

export * from './project-related-icons'
export const BagIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/bag-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const BagIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/bag-icon').then((mod) => mod.BagIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
